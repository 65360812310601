import { TbCircleCheck, TbReload } from "solid-icons/tb";
import { createEffect, Match, Switch } from "solid-js";
import { createStore } from "solid-js/store";
import { StButton } from "~/components/buttons";
import { EmailField } from "~/components/forms";
import { LAST_USED_MAGIC_LINK_EMAIL_KEY } from "~/domains/identity/service/firebase";
import { isAuthenticatedIdentity } from "~/domains/identity/types";
import { useWire } from "~/wire";

const Finish = () => {
  const wire = useWire();

  const [state, setState] = createStore({
    email: "",
    status: "idle" as
      | "idle"
      | "emailFound"
      | "error"
      | "invalidUrl"
      | "needEmail"
      | "needEmailSubmitting",
  });

  const onEmailEntered = (e: SubmitEvent) => {
    e.preventDefault();
    setState("status", "needEmailSubmitting");
    wire.services.identity
      .finishSignInMagicLink(state.email, window.location.href)
      .then(() => {
        window.location.assign("/");
      })
      .catch((err) => {
        console.error(err);
        setState("status", "error");
      });
  };

  createEffect(() => {
    if (
      !isAuthenticatedIdentity(
        wire.services.identity.snapshot.context.identity,
      ) ||
      state.status !== "idle"
    )
      return;
    const email = localStorage.getItem(LAST_USED_MAGIC_LINK_EMAIL_KEY);
    const url = window.location.href;
    const isValid = wire.services.identity.isURLMagicLink(url);

    if (!isValid) {
      setState("status", "invalidUrl");
      return;
    }

    if (!email) {
      setState("status", "needEmail");
      return;
    }

    setState("email", email);

    wire.services.identity
      .finishSignInMagicLink(email, url)
      .then(() => {
        window.location.assign("/");
      })
      .catch((err) => {
        console.error(err);
        setState("status", "error");
      });
  });

  const title = () => {
    if (state.status === "idle") return "Checking credentials...";
    if (state.status === "emailFound") return "Signing you in...";
    if (state.status === "error") return "Oops! Something went wrong";
    if (state.status === "invalidUrl") return "Invalid magic link";
    if (state.status === "needEmail" || state.status === "needEmailSubmitting")
      return "Please enter your email";
  };

  return (
    <div class="flex flex-col gap-4 sm:min-w-96">
      <div>
        <h1 class="text-xl font-semibold bg-clip-text text-transparent bg-text-gradient-dark dark:bg-text-gradient inline">
          {title()}
        </h1>
      </div>

      <div class="w-full">
        <Switch>
          <Match when={state.status === "idle"}>
            <p>Please don't close this page.</p>
          </Match>
          <Match
            when={
              state.status === "needEmail" ||
              state.status === "needEmailSubmitting"
            }
          >
            <p>
              For security purposes we need the email address you used for this
              magic link.
            </p>

            <form
              class="pb-5 pt-10 w-full flex flex-col sm:flex-row gap-4 items-end"
              onSubmit={onEmailEntered}
            >
              <EmailField
                withIcon
                email={state.email}
                setEmail={(value) => setState("email", value)}
                inputProps={{ autofocus: true }}
              />

              <StButton
                icon={TbCircleCheck}
                type="submit"
                loading={state.status === "needEmailSubmitting"}
                disabled={state.status === "needEmailSubmitting"}
                class="flex-shrink-0 w-full sm:w-auto"
              >
                Confirm
              </StButton>
            </form>
          </Match>
          <Match when={state.status === "emailFound"}>
            <p>You will be redirected in a second</p>
          </Match>
          <Match when={state.status === "invalidUrl"}>
            <p class="mb-4">We couldn't verify the link.</p>
            <StButton href="/auth/login" icon={TbReload}>
              Retry
            </StButton>
          </Match>
          <Match when={state.status === "error"}>
            <p class="mb-4">We couldn't sign you in.</p>
            <StButton href="/auth/login" icon={TbReload}>
              Retry
            </StButton>
          </Match>
        </Switch>
      </div>
    </div>
  );
};

export default Finish;
